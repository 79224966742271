import {
  InterAppMessage,
  InterAppMessageResponse,
} from '@arcadehq/shared/types'
import { captureException } from '@sentry/nextjs'
import { AuthUser } from 'next-firebase-auth'
import { isPreprodEnv, isProductionEnv, isStagingEnv } from 'src/helpers'
import { Flow } from 'src/types'

import {
  localChromeExtensionId,
  preprodChromeExtensionId,
  publishedChromeExtensionId,
  stagingChromeExtensionId,
} from '../../constants'
import { getFlowDataDocFromFlow } from './flows'

export const openOrSwitchToEditorTab = async (flowId: string) => {
  const result = await sendMessage({
    name: 'OpenOrSwitchToEditorTab',
    flowId,
  })

  if (result.success) {
    window.close()
  }
}

export const passUserToExtension = async (
  user: Partial<AuthUser> | null
): Promise<boolean> => {
  const result = await sendMessage({
    name: 'UserFromApp',
    user,
  })
  return result.success
}

export const notifyExtensionReadyForPreflight = async (): Promise<boolean> => {
  const result = await sendMessage({
    name: 'ReadyForPreflight',
  })

  if (!result.success) {
    captureException(new Error(result.errorMessage))
    return false
  }

  return true
}

export const getExtensionVersion = async (): Promise<string | null> => {
  const result = await sendMessage({
    name: 'GetVersion',
  })

  if (result.success) {
    return result.reply.version || null
  }

  return null
}

export const passLastEditedFlowToExtension = async (flow: Flow) => {
  await sendMessage({
    name: 'LastEditedFlow',
    flow: getFlowDataDocFromFlow(flow),
  })
}

//
// Internal helpers
//

type Response<Message extends InterAppMessage> =
  | { success: true; reply: InterAppMessageResponse<Message['name']> }
  | { success: false; errorMessage: string }

const sendMessage = async <M extends InterAppMessage>(
  message: M
): Promise<Response<M>> => {
  // In prod, send the message to the prod extension first, then fall back to
  // local to allow testing the local extension against prod. In dev, do the
  // reverse.
  //
  // This allow having both extensions cohabitate and do what you expect by
  // default, but if you want to test across environments, you can do so by
  // enabling only the extension you want to test against.
  const extensionIds = [publishedChromeExtensionId, localChromeExtensionId]
  if (!isProductionEnv()) {
    extensionIds.reverse()
  }

  if (isStagingEnv()) {
    extensionIds.unshift(preprodChromeExtensionId)
    extensionIds.unshift(stagingChromeExtensionId)
  }

  if (isPreprodEnv()) {
    extensionIds.unshift(stagingChromeExtensionId)
    extensionIds.unshift(preprodChromeExtensionId)
  }

  const errors = []

  for (const id of extensionIds) {
    const result = await sendChromeExtensionMessage(id, message)

    if (result.success) {
      return result
    } else {
      errors.push(result.errorMessage)
    }
  }

  return {
    success: false,
    errorMessage: `Failed to send ${message.name} to extension: ${errors.join(
      ' - '
    )}`,
  }
}

const sendChromeExtensionMessage = <Message extends InterAppMessage>(
  extensionId: string,
  message: Message
): Promise<Response<Message>> =>
  new Promise(resolve => {
    if (typeof window === 'undefined') {
      return resolve({
        success: false,
        errorMessage: 'window is not defined',
      })
    }

    const w = window as any
    if (
      typeof w !== 'undefined' &&
      'chrome' in w &&
      'runtime' in w.chrome &&
      'sendMessage' in w.chrome.runtime
    ) {
      w.chrome.runtime.sendMessage(extensionId, message, (reply: any) => {
        if (typeof reply === 'undefined') {
          resolve({
            success: false,
            errorMessage: w.chrome.runtime.lastError,
          })
        } else {
          resolve({
            success: true,
            reply,
          })
        }
      })
    } else {
      resolve({
        success: false,
        errorMessage: 'chrome.runtime.sendMessage not found',
      })
    }
  })
